<template>
  <v-container>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="320">
        <v-card>
          <v-card-title>Legendary Egg</v-card-title>
          <v-card-text>
            Congratulations, You found the Legendary Easter Egg. 
            <br />
            <br />
            
            Happy Hunting,
            <br />
            - H
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text :to="{ name: 'LegendaryLanding' }">
              Start
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    }
  },
}
</script>